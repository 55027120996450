<template>
  <div class="agreement wrapper">
    <div class="p-content">
      <p>
        The Netbang Privacy Policy describe how Netbang handles your personal information while using Netbang.
      </p>
      <p>
        When you buy a game console, a game device or a video game CDKEY of STEAM on Netbang, Netbang may share certain data with the game publisher to support gameplay, enable in-game functionality, investigate problems, facilitate improvement of Netbang or the game, and provide customer support. This information may include:
      </p>
      <ul>
        <li>
          - limited account information, such as your country, your province or state (if you are in Canada or the U.S.), country of purchase, time zone, and your Google Account publication setting;
        </li>
        <li>
          - Netbang and Steam profile information, such as your Netbang or Steam Name, avatar, and a unique identifier that is assigned to you by Netbang;
        </li>
        <li>
          - social data, such as identifiers for your friends on Netbang, only with your additional consent;
        </li>
      </ul>
      <p>
        Each publisher has agreed to use this information in accordance with its privacy policy. Please take time to review the publisher’s privacy policy.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #f0f0f0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100vh;
  box-sizing: border-box;
}
</style>
